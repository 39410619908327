import ImageBox from "./ImageBox";

const Values: any = [
  "/Image/Collaborator1.png",
  "/Image/Collaborator2.svg",
  "/Image/Collaborator3.png",
  "/Image/Collaborator4.png",
  "/Image/Collaborator5.png",
  "/Image/Collaborator6.png",
  "/Image/Collaborator7.png",
];

const CollaboratorSection = () => {
  return (
    <div className="flex sm:flex-wrap sm:items-center sm:justify-center gap-4 overflow-scroll no-scrollbar sm:gap-8">
      {Values.map((item: string, index: any) => (
        <ImageBox key={index} imageURL={item} />
      ))}
    </div>
  );
};

export default CollaboratorSection;
