import CollaboratorSection from "./CollaboratorSection";
import TextHeading from "./TextHeading";

const Collaborator = () => {
  return (
    <div className="flex flex-col sm:gap-14 gap-8">
      <TextHeading subHeading="" title="Ecosystem Partners" subText="" />
      <CollaboratorSection />
    </div>
  );
};

export default Collaborator;
