import ChallengesBox from "./ChallengesBox";

const Values = [
  {
    title: "Cross Platform Asset Purchase",
    subText:
      "Despite the integration of Account Abstraction (AA), users need help with purchasing assets across different platforms due to the requirement of specific assets (e.g., Aevo USD, sUSD).",
  },
  {
    title: "Funding rate ambiguity",
    subText:
      "There is a significant lack of clear information regarding funding rates and their impact on user funds.",
  },
  {
    title: "Funding Constraints",
    subText:
      "Identifying the optimal funding option is challenging as rates fluctuate among partners.",
  },
  {
    title: "Leverage deficiency",
    subText:
      "Many perpetual contracts do not support cross margining, limiting trading efficiency and risk management.",
  },
  {
    title: "Dependence, inadequacy",
    subText:
      "Users face an inability to independently manage their positions, leading to reliance and inefficiency.",
  },
];

const ProblemSection = () => {
  return (
    <div className="flex sm:flex-wrap sm:max-w-[898px] gap-4 overflow-scroll no-scrollbar sm:gap-8">
      {Values.map((item, index) => (
        <ChallengesBox
          key={index}
          gradientBackground="linear-gradient(0deg, rgba(0, 0, 0, 0.00) 87.5%, rgba(188, 79, 79, 0.20) 102.44%), #1C2031"
          isSuccess={false}
          title={item.title}
          subText={item.subText}
        />
      ))}
    </div>
  );
};
export default ProblemSection;
