const TextBox = ({ subHeading, title, subText }: any) => {
  return (
    <div className="flex flex-col gap-5">
      <h2 className="heading">{subHeading}</h2>
      <h1 className="primaryTitle">{title}</h1>
      {subText ? <p className="subText text-subText-text">{subText}</p> : null}
    </div>
  );
};

export default TextBox;
