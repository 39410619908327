import React from "react";

function Chess({ ...props }) {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.00033 4.16667C5.00033 3.24619 5.74652 2.5 6.66699 2.5H13.3337C14.2541 2.5 15.0003 3.24619 15.0003 4.16667V5H5.00033V4.16667ZM3.33366 7.91667C3.33366 6.99619 4.07985 6.25 5.00033 6.25H15.0003C15.9208 6.25 16.667 6.99619 16.667 7.91667V8.75H3.33366V7.91667ZM1.66699 11.6667C1.66699 10.7462 2.41318 10 3.33366 10H16.667C17.5875 10 18.3337 10.7462 18.3337 11.6667V14.1667C18.3337 16.0076 16.8413 17.5 15.0003 17.5H5.00033C3.15938 17.5 1.66699 16.0076 1.66699 14.1667V11.6667ZM7.70866 12.5C7.70866 12.1548 7.98848 11.875 8.33366 11.875H11.667C12.0122 11.875 12.292 12.1548 12.292 12.5C12.292 12.8452 12.0122 13.125 11.667 13.125H8.33366C7.98848 13.125 7.70866 12.8452 7.70866 12.5Z"
        />
      </svg>
    </>
  );
}

export default Chess;
