import React from "react";

function Gas({ ...props }) {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.66699 1.66675C3.45785 1.66675 1.66699 3.45761 1.66699 5.66675V14.3334C1.66699 16.5426 3.45785 18.3334 5.66699 18.3334H14.3337C16.5428 18.3334 18.3337 16.5426 18.3337 14.3334V5.66675C18.3337 3.45761 16.5428 1.66675 14.3337 1.66675H5.66699ZM12.0837 6.87508C11.7385 6.87508 11.4587 7.1549 11.4587 7.50008C11.4587 7.84526 11.7385 8.12508 12.0837 8.12508H12.8664L10.948 11.0515C10.864 11.1797 10.6749 11.1763 10.5955 11.0451L9.00539 8.41815C8.42243 7.45508 7.01441 7.48768 6.47663 8.47669L4.45126 12.2015C4.28637 12.5048 4.39853 12.8843 4.70178 13.0492C5.00503 13.214 5.38453 13.1019 5.54942 12.7986L7.57479 9.07381C7.65162 8.93252 7.85276 8.92787 7.93604 9.06545L9.52619 11.6924C10.0818 12.6104 11.4051 12.6342 11.9934 11.7368L14.0319 8.62731L14.4413 9.44626C14.5957 9.75499 14.9711 9.88013 15.2799 9.72577C15.5886 9.5714 15.7137 9.19598 15.5594 8.88724L14.9564 7.68123C14.7093 7.18717 14.2044 6.87508 13.652 6.87508H12.0837Z"
        />
      </svg>
    </>
  );
}

export default Gas;
