import ProblemSection from "./ProblemSection";
import TextBox from "./TextBox";

const Problems = () => {
  return (
    <div className="flex flex-col justify-center lg:flex-row sm:gap-[69px] gap-6">
      <div className="w-[289px]">
        <TextBox subHeading="PROBLEMS" title="Challenges to overcome" />
      </div>
      <div className="max-w-[880px]">
        <ProblemSection />
      </div>
    </div>
  );
};

export default Problems;
