import InvestorSection from "./InvestorSection";
import TextHeading from "./TextHeading";

const Investor = () => {
  return (
    <div className="flex flex-col sm:gap-14 gap-8">
      <TextHeading subHeading="" title="Backers" subText="" />
      <InvestorSection />
    </div>
  );
};

export default Investor;
