import React from "react";

function House({ ...props }) {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.45801 1.66675C2.35344 1.66675 1.45801 2.56218 1.45801 3.66675V6.33341C1.45801 7.43798 2.35344 8.33341 3.45801 8.33341H7.79134C8.89591 8.33341 9.79134 7.43799 9.79134 6.33342V3.66675C9.79134 2.56218 8.89591 1.66675 7.79134 1.66675H3.45801ZM11.7913 11.6667C10.6868 11.6667 9.79134 12.5622 9.79134 13.6667V16.3334C9.79134 17.438 10.6868 18.3334 11.7913 18.3334H16.1247C17.2292 18.3334 18.1247 17.438 18.1247 16.3334V13.6667C18.1247 12.5622 17.2292 11.6667 16.1247 11.6667H11.7913ZM16.961 5.62508L16.3612 6.22481C16.1172 6.46888 16.1172 6.86461 16.3612 7.10869C16.6053 7.35277 17.001 7.35277 17.2451 7.10869L18.3225 6.03128C18.8921 5.46177 18.8921 4.5384 18.3225 3.96888L17.2451 2.89147C17.001 2.6474 16.6053 2.6474 16.3612 2.89147C16.1172 3.13555 16.1172 3.53128 16.3612 3.77536L16.961 4.37508H13.1247C12.7795 4.37508 12.4997 4.6549 12.4997 5.00008C12.4997 5.34526 12.7795 5.62508 13.1247 5.62508L16.961 5.62508ZM3.22144 16.2248L2.62171 15.6251H6.45801C6.80319 15.6251 7.08301 15.3453 7.08301 15.0001C7.08301 14.6549 6.80319 14.3751 6.45801 14.3751H2.62171L3.22144 13.7754C3.46552 13.5313 3.46552 13.1356 3.22144 12.8915C2.97736 12.6474 2.58163 12.6474 2.33755 12.8915L1.26014 13.9689C0.690628 14.5384 0.69063 15.4618 1.26014 16.0313L2.33756 17.1087C2.58163 17.3528 2.97736 17.3528 3.22144 17.1087C3.46552 16.8646 3.46552 16.4689 3.22144 16.2248Z"
        />
      </svg>
    </>
  );
}

export default House;
