/* eslint-disable react/jsx-no-undef */
import { Inter } from "next/font/google";
import Problems from "@/components/Problems";
import Solutions from "@/components/Solutions";
import Collaborator from "@/components/Collaborator";
import Investor from "@/components/Investor";
import Section from "@/components/Section";
import Explore from "@/components/Explore";
import Header from "@/components/Header";
import TextBox from "@/components/TextBox";
import WhyUs from "@/components/WhyUs";
import RoadMap from "@/components/RoadMap";
import Image from "next/image";
import Footer from "@/components/Footer";
import HeroSection from "@/components/HeroSection";

const inter = Inter({
  subsets: ["latin"],
  weight: ["400", "300", "700", "900"],
  variable: "--font-inter",
});

export default function Home() {
  return (
    <main
      className={`min-h-screen  bg-main-background w-full overflow-hidden ${inter.className}`}
    >
      <div className="relative z-0">
        <div className="absolute -top-[144px] w-[298px] h-[288px] rounded-[298px] opacity-70 -z-10 bg-[#3861FC] blur-[225px] block sm:hidden" />
        <div className="absolute -top-[490px] w-[600px] h-[600px] rounded-[298px] opacity-70 -z-10 left-1/2 -translate-x-1/2 transform bg-[#3861FC] blur-[225px] hidden sm:block" />

        <Header />
        <HeroSection />
        <div className="flex flex-col sm:gap-[120px] gap-16 px-4 sm:px-0">
          <Problems />
          <Solutions />
          <WhyUs />
          <Explore />
          <Section />
          <Collaborator />
          <Investor />
          <RoadMap />
          <Footer />
        </div>
      </div>
    </main>
  );
}
