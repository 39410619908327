/* eslint-disable react/jsx-key */
import React from "react";
import { isMobile } from "react-device-detect";

const Item = ({
  title = "",
  items = [],
  direction = true,
}: {
  title: string;
  items: Array<string>;
  direction: boolean;
}) => {
  return (
    <div className="flex flex-col w-[250px]">
      <dl>
        {isMobile && (
          <dt className="sm:text-xl text-lg leading-6 font-semibold text-white mb-2">
            {title}
          </dt>
        )}
        {direction && !isMobile && (
          <dt className="text-2xl leading-8 font-semibold text-chosen-primary text-white mb-2">
            {title}
          </dt>
        )}
        <div className="flex flex-row">
          {!isMobile && (
            <div className="flex flex-col">
              {direction && (
                <div
                  className="bg-[#FDFCFF] rounded-full"
                  style={{
                    width: "9px",
                    height: "9px",
                    transform: "translate(-3.5px)",
                  }}
                />
              )}
              <div
                className="w-0 h-full"
                style={{
                  border: "1px solid #E3E3E3",
                }}
              />

              {!direction && (
                <div
                  className="bg-[#FDFCFF] rounded-full"
                  style={{
                    width: "9px",
                    height: "9px",
                    transform: "translate(-3.5px)",
                  }}
                />
              )}
            </div>
          )}

          <div style={{ maxWidth: "350px", width: "300px" }}>
            <ul className="my-6 translate-x-5">
              {items.map((item: string) => (
                <li
                  key={item}
                  className="mt-2 text-base font-medium leading-6 list-disc text-left text-highlight text-white"
                >
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
        {!direction && !isMobile && (
          <dt className="text-2xl leading-8 font-semibold text-chosen-primary text-white mt-2">
            {title}
          </dt>
        )}
      </dl>
    </div>
  );
};

const RoadMapSection = ({ t }: any, { roadMapRef }: any) => {
  const items = [
    <Item
      title={"May - 2024"}
      items={[`${"Integration of GMX"}`, `${"Integration of Hyperliquid"}`]}
      direction
    />,
    <Item
      title={"June - 2024"}
      items={[`${"Integration of Aevo"}`]}
      direction={false}
    />,
    <Item
      title={"August - 2024"}
      items={[`${"Integration of KTX"}`]}
      direction
    />,
    <Item
      title={"October - 2024"}
      items={[`${"Oddz native perp with Perp chain testnet"}`]}
      direction={false}
    />,
    <Item
      title={"Q1 - 2025"}
      items={[
        `${"Oddz perp chain mainnet"}`,
        `${"Add native oddz perp as one of the aggregation partner"}`,
      ]}
      direction
    />,
  ];

  return (
    <main
      className={`flex flex-col justify-center items-center sm:mt-[120px] mt-4`}
    >
      <ul className="flex justify-evenly max-w-7xl flex-col sm:flex-row">
        {items.map((item, index) => {
          return (
            <li
              key={index}
              style={
                isMobile
                  ? {
                      borderLeft: "6px solid #D3D3D31A",
                    }
                  : {}
              }
            >
              <div
                className="flex items-center justify-center z-50 absolute rounded-full"
                style={
                  isMobile
                    ? {
                        width: "16px",
                        height: "16px",
                        background: "rgba(252, 253, 255)",
                        transform: "translate(-11px,-16px)",
                      }
                    : {
                        width: "32px",
                        height: "32px",
                        background: "rgba(252, 253, 255)",
                        transform: "translate(-16px,-16px)",
                      }
                }
              >
                <div
                  className="bg-[#FDFCFF] rounded-full"
                  style={{
                    width: "16px",
                    height: "16px",
                  }}
                />
              </div>
              {items.length - 1 !== index && !isMobile && (
                <div className="h-1 w-full bg-secondary-dark opacity-10 rounded" />
              )}
              <div
                style={
                  isMobile
                    ? { marginLeft: "24px" }
                    : {
                        transform: `${
                          index % 2 === 0 ? "translateY(calc(-100% - 4px))" : ""
                        }`,
                      }
                }
              >
                {item}
              </div>
            </li>
          );
        })}
      </ul>
    </main>
  );
};
export default RoadMapSection;
