import { useState } from "react";
import TextBox from "./TextBox";
import Grid from "./svg/Grid.svg";
import Chess from "./svg/Chess.svg";
import Gas from "./svg/Gas.svg";
import Calculator from "./svg/Calculator.svg";
import Solution from "./svg/Solution.svg";
import House from "./svg/House.svg";

const Tabs = [
  {
    title: "Unrivaled Aggregation",
    description:
      "Gain access to deep liquidity from leading decentralized exchanges including GMX, hyperliquid, Aevo, and more. With our aggregator, you can tap into a vast array of markets with ease.",
    Icon: Grid,
  },
  {
    title: "Self-Custodial Solutions",
    description:
      "Retain complete control over your assets with our self-custodial trading approach. Say goodbye to centralized custody and enjoy the peace of mind that comes with managing your funds securely.",
    Icon: Solution,
  },
  {
    title: "Enhanced Leverage Options",
    description:
      "Amplify your trading potential with leverage options of up to 100x. Whether you're a seasoned trader or just starting out, our platform provides you with the tools to maximize your returns while managing risk effectively.",
    Icon: Chess,
  },
  {
    title: "Optimized Trading Costs",
    description:
      "Keep more of your profits with competitive trading fees. We understand the importance of minimizing costs, and our platform is designed to ensure that you get the most value out of every trade.",
    Icon: Gas,
  },
  {
    title: "Diverse Market Offerings",
    description:
      "Explore a wide range of markets including cryptocurrencies, commodities, indices, and more. With Perpetuals Nexus, you have access to an extensive selection of assets to diversify your portfolio and seize lucrative opportunities.",
    Icon: Calculator,
  },
  {
    title: "Smart Execution Technology",
    description:
      "Benefit from lightning-fast order execution powered by advanced algorithms. Our platform intelligently routes your positions to optimize execution and minimize slippage, giving you an edge in the market.",
    Icon: House,
  },
];

const ArrowIcon = ({ props }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      {...props}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13.4628 10.1937C13.4246 10.2847 13.3603 10.3624 13.278 10.4169C13.1956 10.4714 13.0991 10.5003 13.0003 10.5H3.00034C2.90163 10.5003 2.80503 10.4714 2.72273 10.4169C2.64042 10.3624 2.57609 10.2847 2.53784 10.1937C2.50182 10.1014 2.49268 10.0007 2.51148 9.90339C2.53029 9.80606 2.57626 9.71604 2.64409 9.64375L7.64409 4.64375C7.73966 4.55134 7.8674 4.49968 8.00034 4.49968C8.13328 4.49968 8.26102 4.55134 8.35659 4.64375L13.3566 9.64375C13.4244 9.71604 13.4704 9.80606 13.4892 9.90339C13.508 10.0007 13.4989 10.1014 13.4628 10.1937Z"
        fill="white"
      />
    </svg>
  );
};

const WhyUs = () => {
  const [openIndex, setOpenIndex] = useState<any>(0);
  const handleItemClick = (index: number) => {
    setOpenIndex((prevIndex: number) => (prevIndex === index ? null : index));
  };

  return (
    <div className="flex relative flex-col justify-center lg:flex-row-reverse sm:gap-[200px] gap-6">
      <div className="absolute top-[30px] -right-[500px] w-[600px] h-[600px] rounded-[600px] transform bg-[#3861FC] blur-[225px] hidden sm:block" />

      <div className="sm:w-[408px]">
        <TextBox
          subHeading="WHY US"
          title="The most flexible Perpetual trading platform"
        />
      </div>
      <div className="max-w-[514px]">
        <div
          className={
            "flex flex-col w-full gap-3 sm:w-[505px] items-center justify-start relative overflow-auto  no-visible-scrollbar "
          }
        >
          {Tabs.map(({ title, description, Icon }, idx) => (
            <button
              key={title}
              className="w-full"
              onClick={() => {
                handleItemClick(idx);
              }}
            >
              <div
                className={`px-8 py-4 rounded-[8px] flex gap-4 justify-between w-full text-left focus:outline-none ${
                  openIndex === idx && "border-[0.75px] border-[#FF33]"
                }`}
                style={{
                  background:
                    openIndex === idx
                      ? "linear-gradient(180deg, #3861FC 0%, #3861FC 100%)"
                      : "#1C2136",
                }}
              >
                <div
                  className={`flex items-center justify-center rounded-[4px]`}
                  style={
                    openIndex === idx
                      ? {
                          width: "48px",
                          height: "48px",
                          background:
                            "linear-gradient(113deg, rgba(255, 255, 255, 0.40) -13.95%, rgba(255, 255, 255, 0.10) 86.85%)",
                          boxShadow: "0px 4px 24px -2px rgba(0, 0, 0, 0.20)",
                          backdropFilter: "blur(20px)",
                          padding: "8px",
                        }
                      : {
                          width: "32px",
                          height: "32px",
                          background: "#303048",
                          padding: "6px",
                        }
                  }
                >
                  <Icon
                    className={` ${
                      openIndex === idx
                        ? "w-[32px] h-[32px] fill-[#FDFCFF]"
                        : "w-[20px] h-[20px] fill-[#C3C3C3]"
                    }`}
                  />
                </div>
                <div className="w-full">
                  <div className="flex justify-between mt-1 items-center w-full gap-6">
                    <div className="flex-1 grow w-full">
                      <span className="text-[#FDFCFF] text-[16px] leading-[20px] ">
                        {title}
                      </span>
                    </div>
                    {openIndex === idx ? (
                      <div className="">
                        <ArrowIcon />
                      </div>
                    ) : (
                      <div className=" rotate-180">
                        <ArrowIcon />
                      </div>
                    )}
                  </div>
                  {openIndex === idx && (
                    <div className="mt-2 text-[#C5C5C5] text-[14px] leading-[20px]">
                      {description}
                    </div>
                  )}
                </div>
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyUs;
