import Image from "next/image";

const FooterOptions = [
  {
    icon: "Image/Twitter.svg",
    href: "https://twitter.com/oddz_finance",
  },
  {
    icon: "Image/Github.svg",
    href: "https://github.com/oddz-finance",
  },
  // {
  //   icon: "Image/Instagram.svg",
  //   href: "https://www.instagram.com/oddz.finance/",
  // },
  // {
  //   icon: "Image/Facebook.svg",
  //   href: "https://www.facebook.com/Oddz.fi",
  // },
  // {
  //   icon: "Image/Medium.svg",
  //   href: "https://medium.com/oddz-finance",
  // },
  // {
  //   icon: "Image/Telegram.svg",
  //   href: "https://t.me/oddz_finance",
  // },
  // {
  //   icon: "Image/Discord.svg",
  //   href: "https://discord.gg/N875exCUfm",
  // },
];

const Footer = () => {
  return (
    <div className="max-w-[1216px] w-full mx-auto">
      <div className=" border-t-[1px] w-full border-[#1F1F32] pt-8 sm:pb-14 pb-6 flex flex-col sm:flex-row justify-center items-center gap-8 sm:gap-0 sm:justify-between">
        <div className="text-[#c3c3c38f] w-full flex-grow sm:text-[16px] sm:leading-[28.8px] text-[14px]">
          © 2024 Corvell Labs Pvt Ltd. All rights reserved.
        </div>
        <div className="flex sm:gap-12 gap-4">
          {FooterOptions.map((item, index) => (
            <a key={index} href={item.href} target="_blank" rel="noreferrer">
              <Image src={item.icon} height={24} width={24} alt="Icon" />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
