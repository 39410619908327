import { Popover, Transition } from "@headlessui/react";
import Image from "next/image";
import { Fragment } from "react";

const HeaderOptions = [
  {
    title: "Bridge",
    link: "https://bridge.oddz.fi/",
  },
  {
    title: "Documentation",
    link: "https://docs.oddz.fi/oddz-wiki/",
  },
  {
    title: "Papers",
    link: "https://docsend.com/view/mefxr8h3ymzuhwqk",
  },
  {
    title: "Audit",
    link: "https://github.com/oddz-finance/audits/blob/master/SlowMist%20Audit%20Report%20-%20Oddz%20Finance.pdf",
  },
  {
    title: "Github",
    link: "https://github.com/oddz-finance",
  },
];

const Header = () => {
  return (
    <div className="sm:py-8 py-7 sm:px-0 px-4 flex justify-between items-center mx-auto max-w-[1216px]">
      <Image
        src={"/Image/OddzFullLogo.svg"}
        width={121}
        height={40}
        alt="Icon"
        priority={true}
        className="hidden sm:block"
      />
      <Image
        className="block sm:hidden"
        src={"/Image/OddzLogo.svg"}
        width={42}
        height={32}
        priority={true}
        alt="Icon"
      />
      <div className="flex gap-4 sm:gap-[46px] items-center">
        <div className="flex gap-4 items-center">
          <div className="hidden sm:flex gap-10 mr-6">
            {HeaderOptions.map((item, index) => (
              <a
                href={item.link}
                className="header"
                target="_blank"
                rel="noreferrer"
                key={index}
              >
                {item.title}
              </a>
            ))}
          </div>
          <button
            type="button"
            className="border-heading-text border px-8 py-3 text-title-text text-[14px] leading-4 rounded-[4px]"
            onClick={() => window.open("https://app.oddz.fi/", "_blank")}
          >
            Options
          </button>
          <button
            type="button"
            className="bg-heading-text border-heading-text border px-8 py-3 text-title-text text-[14px] leading-4 rounded-[4px]"
            onClick={() => window.open("https://perp.oddz.fi/", "_blank")}
          >
            Perp
          </button>
        </div>
        <Popover>
          <div className="sm:hidden">
            <Popover.Button>
              <span className="sr-only">Open menu</span>
              <Image
                src={"/Image/SnackBar.svg"}
                width={24}
                height={18}
                alt="Icon"
              />
            </Popover.Button>
          </div>
          <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute inset-x-0 top-0  transition origin-top-right outline-none transform md:hidden z-[9999] h-full bg-[#13172380]"
            >
              <div className=" rounded-b-xl bg-[#131723] p-5 pb-8">
                <div className="ring-1 ring-black outline-none ring-opacity-5 divide-y-2">
                  <nav className="grid gap-10">
                    <div className="flex justify-between items-center">
                      <Image
                        src={"/Image/OddzFullLogo.svg"}
                        width={121}
                        height={40}
                        alt="Icon"
                      />
                      <div>
                        <Popover.Button>
                          <span className="sr-only">Close menu</span>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.2431 7.75738L7.75781 16.2427M16.2431 16.2426L7.75781 7.75732"
                              stroke="#D9D9D9"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </Popover.Button>
                      </div>
                    </div>
                    {HeaderOptions.map((item, index) => (
                      <a
                        href={item.link}
                        className="header outline-none"
                        key={index}
                      >
                        {item.title}
                      </a>
                    ))}
                    <div className="flex gap-4">
                      <button
                        type="button"
                        className="border-heading-text border px-8 py-3 text-title-text text-[14px] leading-4 rounded-[4px]"
                        onClick={() =>
                          window.open("https://perp.oddz.fi/", "_blank")
                        }
                      >
                        Options
                      </button>

                      <button
                        type="button"
                        className="bg-heading-text px-8 py-3 w-fit text-title-text text-[14px] leading-4 rounded-[4px]"
                        onClick={() =>
                          window.open("https://app.oddz.fi/", "_blank")
                        }
                      >
                        Perp
                      </button>
                    </div>
                  </nav>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </div>
    </div>
  );
};

export default Header;
