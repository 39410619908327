import React from "react";

function Solution({ ...props }) {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.83366 5.9152V4.5C5.83366 3.39543 6.72909 2.5 7.83366 2.5H16.3337C17.4382 2.5 18.3337 3.39543 18.3337 4.5V11.3333C18.3337 12.4379 17.4382 13.3333 16.3337 13.3333H12.5745C11.9978 13.3333 11.4492 13.0844 11.0694 12.6503L6.32851 7.23221C6.0095 6.86762 5.83366 6.39964 5.83366 5.9152ZM9.16699 5.5C8.75278 5.5 8.41699 5.83579 8.41699 6.25C8.41699 6.66421 8.75278 7 9.16699 7H15.0003C15.4145 7 15.7503 6.66421 15.7503 6.25C15.7503 5.83579 15.4145 5.5 15.0003 5.5H9.16699ZM10.917 9.58333C10.917 9.16912 11.2528 8.83333 11.667 8.83333H15.0003C15.4145 8.83333 15.7503 9.16912 15.7503 9.58333C15.7503 9.99755 15.4145 10.3333 15.0003 10.3333H11.667C11.2528 10.3333 10.917 9.99755 10.917 9.58333ZM7.50033 10.8333C7.50033 11.7538 6.75413 12.5 5.83366 12.5C4.91318 12.5 4.16699 11.7538 4.16699 10.8333C4.16699 9.91286 4.91318 9.16667 5.83366 9.16667C6.75413 9.16667 7.50033 9.91286 7.50033 10.8333ZM1.66699 15.1011C1.66699 14.1248 2.45845 13.3333 3.43476 13.3333H3.47664C3.91866 13.3333 4.34259 13.5089 4.65515 13.8215C5.30602 14.4724 6.3613 14.4724 7.01217 13.8215C7.32473 13.5089 7.74865 13.3333 8.19068 13.3333H8.23256C9.20887 13.3333 10.0003 14.1248 10.0003 15.1011V15.5C10.0003 16.6046 9.1049 17.5 8.00033 17.5H3.66699C2.56242 17.5 1.66699 16.6046 1.66699 15.5V15.1011Z"
        />
      </svg>
    </>
  );
}

export default Solution;
