const ImageBox = ({ imageURL }: any) => {
  return (
    <div>
      <div className="sm:w-[304px] w-[262px] h-[96px] flex items-center justify-center bg-partners rounded-lg">
        <img src={imageURL} alt="logo" />
      </div>
    </div>
  );
};

export default ImageBox;
