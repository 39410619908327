import RoadMapSection from "./RoadmapSection";
import TextHeading from "./TextHeading";

const RoadMap = () => {
  return (
    <div className="flex flex-col sm:gap-14 gap-8">
      <TextHeading
        subHeading="ROADMAP"
        title="We have many more things lined up for 2024"
      />
      <RoadMapSection />
    </div>
  );
};

export default RoadMap;
