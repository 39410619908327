import TextBox from "./TextBox";

const Section = () => {
  return (
    <div className="flex flex-col relative sm:flex-row gap-16 sm:items-center sm:justify-center sm:gap-[180px]">
      <div className="absolute top-[0] -left-[500px] w-[600px] h-[600px] rounded-[600px] transform bg-[#5451C9] blur-[225px] hidden sm:block" />

      <div className="sm:w-[408px]">
        <TextBox
          subHeading="ODDZ DEX"
          title="Explore Oddz with Oddz DEX Integration"
          subText="Our Oddz DEX Package Intends to give other platforms and their user the opportunity to explore Options Trading Via the Oddz UI, thereby assisting them in hedging their trades when buying or selling cryptocurrencies."
        />
      </div>
      <div className="sm:w-[408px]">
        <TextBox
          subHeading="COMING SOON"
          title="First perp and options platform on Bitcoin L2"
          subText="We plan to deploy Oddz on Bitcoin Layer 2 solutions to enhance scalability and reduce costs. Our goal is to offer advanced trading in derivatives like perpetual swaps and options, increasing participation and innovation."
        />
      </div>
    </div>
  );
};

export default Section;
