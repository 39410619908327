import React from "react";

function Grid({ ...props }) {
  return (
    <>
      <svg
        width="32"
        height="32"
        {...props}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.1977 7.00008H20.803C22.353 4.90651 20.2648 1.97645 17.6709 2.81321L16.4339 3.21227C16.1523 3.30313 15.8485 3.30313 15.5668 3.21227L14.3298 2.81321C11.7359 1.97644 9.64774 4.90651 11.1977 7.00008ZM20.6588 9.00008H11.3419C9.58287 9.97405 8.20863 11.6002 7.57937 13.6139L5.91271 18.9472C4.30288 24.0987 8.15143 29.3334 13.5485 29.3334H14.8365C14.3092 28.5775 14 27.6582 14 26.6667C14 25.6752 14.3092 24.756 14.8365 24.0001C14.3092 23.2442 14 22.3249 14 21.3334C14 18.7561 16.0893 16.6667 18.6667 16.6667H25.3754L24.4213 13.6139C23.7921 11.6002 22.4178 9.97405 20.6588 9.00008ZM18.6667 18.6667C17.1939 18.6667 16 19.8607 16 21.3334C16 22.8062 17.1939 24.0001 18.6667 24.0001C17.1939 24.0001 16 25.194 16 26.6667C16 28.1395 17.1939 29.3334 18.6667 29.3334H26.6667C28.1394 29.3334 29.3333 28.1395 29.3333 26.6667C29.3333 25.194 28.1394 24.0001 26.6667 24.0001C28.1394 24.0001 29.3333 22.8062 29.3333 21.3334C29.3333 19.8607 28.1394 18.6667 26.6667 18.6667H25.7778H18.6667Z"
        />
      </svg>
    </>
  );
}

export default Grid;
