import SolutionsSection from "./SolutionsSection";
import TextBox from "./TextBox";

const Solutions = () => {
  return (
    <div className="flex flex-col justify-center lg:flex-row sm:gap-[69px] gap-6">
      <div className="w-[289px]">
        <TextBox subHeading="SOLUTIONS" title="Overcoming obstacles" />
      </div>
      <div className="max-w-[880px]">
        <SolutionsSection />
      </div>
    </div>
  );
};

export default Solutions;
