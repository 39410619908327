import ExploreBox from "./ExploreBox";

const Values = [
  {
    title: "Provide Liquidity",
    iconUrl: "/Image/Liquidity.svg",
    subText:
      "Provide liquidity to your favourite pools and earn option premium as rewards.",
  },
  {
    title: "Better Perp Aggregation",
    iconUrl: "/Image/Perp.svg",
    subText:
      "Get top perp trading opportunities with the best prices across chains in one place.",
  },
  {
    title: "Stake and Earn",
    iconUrl: "/Image/Stake.svg",
    subText:
      "Stake your tokens to earn a share in settlement fees along with staking rewards.",
  },
  {
    title: "Truly Multichain",
    iconUrl: "/Image/MultiChain.svg",
    subText:
      "Trade perps across multiple chains including Bitcoin Layer 2s, EVM and Non-EVM chains.",
  },
];

const ExploreSection = () => {
  return (
    <div className="flex sm:flex-wrap sm:max-w-[898px] gap-4 overflow-scroll no-scrollbar sm:gap-8">
      {Values.map((item, index) => (
        <ExploreBox
          key={index}
          title={item.title}
          icon={item.iconUrl}
          subText={item.subText}
        />
      ))}
    </div>
  );
};
export default ExploreSection;
