import ExploreSection from "./ExploreSection";
import TextBox from "./TextBox";

const Explore = () => {
  return (
    <div className="flex flex-col justify-center lg:flex-row sm:gap-[200px] gap-6">
      <div className="sm:w-[408px]">
        <TextBox
          subHeading="EXPLORE"
          title="Explore all the possibilities"
          subText=""
        />
      </div>
      <div className="max-w-[604px]">
        <ExploreSection />
      </div>
    </div>
  );
};

export default Explore;
