const TextHeading = ({ subHeading, title, subText }: any) => {
  return (
    <div className="flex flex-col sm:items-center items-start justify-center">
      <h2 className="heading">{subHeading}</h2>
      <h1 className="title sm:mt-4 mt-5">{title}</h1>
      {subText ? (
        <p className="subText text-subText-text sm:mt-6 mt-5">{subText}</p>
      ) : null}
    </div>
  );
};

export default TextHeading;
