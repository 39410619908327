const ChallengesBox = ({
  isSuccess,
  gradientBackground,
  title,
  subText,
}: any) => {
  return (
    <div>
      <div
        className={`flex flex-col box-content h-[248px] w-[270px] overflow-hidden rounded-xl border-[1px] ${
          isSuccess ? "border-green-primary" : "border-red-primary"
        } bg-main-background backdrop-blur-[2px]`}
      >
        <div className="p-6 flex-1 grow w-[270px]">
          <p className="subText text-primary">{subText}</p>
        </div>
        <div
          className="px-4 py-4 w-[270px]"
          style={{
            background: gradientBackground,
          }}
        >
          <h1 className="title3">{title}</h1>
        </div>
      </div>
    </div>
  );
};
export default ChallengesBox;
