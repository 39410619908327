import Image from "next/image";

const ExploreBox = ({ title, icon, subText }: any) => {
  return (
    <div>
      <div className="py-10 px-7 rounded-xl bg-explore backdrop-blur-[2px] w-[285px] h-[329px]">
        <div className="items-center flex justify-center mb-2">
          <Image src={icon} width={131} height={131} alt="Icon"/>
        </div>
        <h1 className="title1 items-center whitespace-nowrap text-center mb-4">
          {title}
        </h1>
        <p className="subText text-primary text-center items-center">
          {subText}
        </p>
      </div>
    </div>
  );
};
export default ExploreBox;
