import React from "react";

function Calculator({ ...props }) {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.33366 1.66675C2.41318 1.66675 1.66699 2.41294 1.66699 3.33341V5.00008C1.66699 5.92056 2.41318 6.66675 3.33366 6.66675H3.54199V13.3334H3.33366C2.41318 13.3334 1.66699 14.0796 1.66699 15.0001V16.6667C1.66699 17.5872 2.41318 18.3334 3.33366 18.3334H5.00033C5.9208 18.3334 6.66699 17.5872 6.66699 16.6667V16.4584H13.3337V16.6667C13.3337 17.5872 14.0799 18.3334 15.0003 18.3334H16.667C17.5875 18.3334 18.3337 17.5872 18.3337 16.6667V15.0001C18.3337 14.0796 17.5875 13.3334 16.667 13.3334H16.4587V6.66675H16.667C17.5875 6.66675 18.3337 5.92056 18.3337 5.00008V3.33341C18.3337 2.41294 17.5875 1.66675 16.667 1.66675H15.0003C14.0799 1.66675 13.3337 2.41294 13.3337 3.33341V3.54175L6.66699 3.54175V3.33341C6.66699 2.41294 5.9208 1.66675 5.00033 1.66675H3.33366ZM6.66699 4.79175V5.00008C6.66699 5.92056 5.9208 6.66675 5.00033 6.66675H4.79199V13.3334H5.00033C5.9208 13.3334 6.66699 14.0796 6.66699 15.0001V15.2084H13.3337V15.0001C13.3337 14.0796 14.0799 13.3334 15.0003 13.3334H15.2087V6.66675H15.0003C14.0799 6.66675 13.3337 5.92056 13.3337 5.00008V4.79175L6.66699 4.79175Z"
        />
      </svg>
    </>
  );
}

export default Calculator;
