import ImageBox from "./ImageBox";

const Values: any = [
  "/Image/Backed1.png",
  "/Image/Backed2.png",
  "/Image/Backed3.png",
  "/Image/Backed4.png",
  "/Image/Backed5.png",
  "/Image/Backed6.png",
  "/Image/Backed7.png",
  "/Image/Backed8.png",
  "/Image/Backed9.png",
  "/Image/Backed10.png",
];

const InvestorSection = () => {
  return (
    <div className="flex sm:flex-wrap sm:items-center sm:justify-center gap-4 overflow-scroll no-scrollbar sm:gap-8">
      {Values.map((item: string, index: any) => (
        <ImageBox key={index} imageURL={item} />
      ))}
    </div>
  );
};

export default InvestorSection;
