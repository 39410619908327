import ChallengesBox from "./ChallengesBox";

const Values = [
  {
    title: "Simplified purchases",
    subText:
      "We streamline purchases by enabling one-click transactions with any held cryptocurrency.",
  },
  {
    title: "Improved UI",
    subText:
      "We aim to enhance the UI, offering detailed insights on funding rates and their impact on user funds.",
  },
  {
    title: "Leveraged funding",
    subText:
      "We aim to introduce more leverage and margin support through Oddz Liquidity.",
  },
  {
    title: "Exploring Cross-Leverage",
    subText:
      "Cross-Leverage Solution: Actively managing user positions off-chain to enable cross-leverage across all protocols, enhancing trading efficiency and flexibility.",
  },
  {
    title: "Empowering user autonomy",
    subText:
      "Equip users with advanced tools to autonomously manage and adjust their positions effectively.",
  },
];

const SolutionsSection = () => {
  return (
    <div className="flex sm:flex-wrap sm:max-w-[898px] gap-4 overflow-scroll no-scrollbar sm:gap-8">
      {Values.map((item, index) => (
        <ChallengesBox
          key={index}
          gradientBackground="linear-gradient(0deg, rgba(0, 0, 0, 0.00) 87.5%, rgba(18, 146, 54, 0.20) 101.75%), #1C2031"
          isSuccess={true}
          title={item.title}
          subText={item.subText}
        />
      ))}
    </div>
  );
};
export default SolutionsSection;
