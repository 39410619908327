import Image from "next/image";

const HeroWeb = () => {
  return (
    <div className="flex flex-col gap-[52px] mt-[131px]  mb-[127px]">
      <div className="flex flex-col gap-4 items-center">
        <div className="flex gap-10 items-center">
          <h1 className="text-[#FDFCFF] max-w-[751px] font-[900] text-[94px] leading-[104px]">
            Perps <span className="text-[64px]">&</span> Options
          </h1>
          <p className="text-[#C5C5C5] text-[16px] leading-[24px] max-w-[415px]">
            Trade perps and options in one place on Base, Arbitrum and Bitcoin
            L2s
          </p>
        </div>
        <div className="flex gap-8 items-center">
          <div className="flex gap-4 items-center">
            <button
              onClick={() => window.open("https://perp.oddz.fi/", "_blank")}
              type="button"
              className="bg-heading-text px-8 py-4 text-title-text text-[20px] leading-6 rounded-[4px]"
            >
              Launch Perp
            </button>
            <button
              onClick={() => window.open("https://app.oddz.fi/", "_blank")}
              type="button"
              className="border-heading-text border px-8 py-4 text-title-text text-[20px] leading-6 rounded-[4px]"
            >
              Launch Options
            </button>
          </div>

          <h1 className="text-[#FDFCFF] text-[75px] -tracking-[1.76px] leading-[104px]">
            on Bitcoin L2s & EVMs
          </h1>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <div className=" relative rounded-[16px]">
          <div className="absolute -top-[70px] -left-[70px] w-[140px] h-[140px] rounded-[298px] transform bg-[#3861FC] blur-[100px]" />
          <div className="absolute bottom-0 right-0 w-[140px] h-[140px] rounded-[298px] transform bg-[#3861FC] blur-[100px]" />

          <Image
            src="/Image/LandingPage.png"
            className="z-10"
            width={1216}
            height={720}
            alt="landing"
            priority={true}
          />
        </div>
      </div>
    </div>
  );
};

const HeroMweb = () => {
  return (
    <div className="mt-20 mb-16 flex flex-col gap-8">
      <div className="flex flex-col gap-5">
        <h1 className="text-[#FDFCFF]  font-[900] text-[56px] leading-[64px]">
          Perps <span className="text-[32px]">&</span> Options
        </h1>
        <h2 className="text-[#FDFCFF] text-[32px] leading-[40px] -tracking-[0.64px]">
          on Bitcoin L2s & EVMs
        </h2>
        <p className="text-[#C5C5C5] text-[16px] leading-[24px]">
          Options and Perps all in one place
        </p>
      </div>
      <div className="flex gap-2 items-center">
        <button
          onClick={() => window.open("https://perp.oddz.fi/", "_blank")}
          type="button"
          className="bg-heading-text whitespace-nowrap w-fit px-4 py-4 text-title-text text-[16px]  rounded-[4px]"
        >
          Launch Perp
        </button>
        <button
          onClick={() => window.open("https://app.oddz.fi/", "_blank")}
          type="button"
          className="border-heading-text whitespace-nowrap border w-fit px-4 py-4 text-title-text text-[16px]  rounded-[4px]"
        >
          Launch Options
        </button>
      </div>
    </div>
  );
};

const HeroSection = () => {
  return (
    <>
      <div className="block sm:hidden px-4">
        <HeroMweb />
      </div>
      <div className="hidden sm:block">
        <HeroWeb />
      </div>
    </>
  );
};

export default HeroSection;
